<template>
  <layout-view navTitle="Фильтры">
    <template v-slot:widgets>
      <v-row>
        <v-col cols="3">
          <card-view title="Сумма оказанных услуг" :loading="loadState.isLoading">
            <template v-slot:content>
              <div class="text-center" v-if="loadState.isLoading">
                <v-progress-circular               
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
              <h2 v-else class="font-weight-medium mb-2">{{$tools.price(sumTotal)}}</h2>
            </template>
          </card-view>
        </v-col>
        <v-col cols="3">
          <card-view title="Сумма оплаченных услуг" :loading="loadState.isLoading">
            <template v-slot:content>
              <div class="text-center" v-if="loadState.isLoading">
                <v-progress-circular               
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
              <h2 v-else class="font-weight-medium mb-2">{{$tools.price(sumPayedTotal)}}</h2>
            </template>
          </card-view>
        </v-col>
        <v-col cols="3">
          <card-view title="Сумма не оплаченных услуг" :loading="loadState.isLoading">
            <template v-slot:content>
              <div class="text-center" v-if="loadState.isLoading">
                <v-progress-circular               
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
              <h2 v-else class="font-weight-medium mb-2">{{$tools.price(sumUnPayedTotal)}}</h2>
            </template>
          </card-view>
        </v-col>
        <v-col cols="3">
          <card-view title="Сумма отмененных услуг" :loading="loadState.isLoading">
            <template v-slot:content>
              <div class="text-center" v-if="loadState.isLoading">
                <v-progress-circular               
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
              <h2 v-else class="font-weight-medium mb-2">{{$tools.price(sumCanceledTotal)}}</h2>
            </template>
          </card-view>
        </v-col>
      </v-row>
    </template>
    <template v-slot:nav>
      <div class="px-4">

        <date-interval name="Интервал" :valueFrom.sync="dateFrom" :valueTo.sync="dateTo" />

        <v-text-field class="mb-3" v-model="name" clearable label="Наименование услуги" hide-details outlined dense/>

        <services-select @services-selected="multipleQueryString('services_ids',$event)" :comBranchsSelected="comBranchsSelected"/>


        <label-view title="Цена" class="mt-4">
          <template v-slot:content>
            <v-row class="pt-2 px-1 pb-1">
              <v-col>
                <v-text-field v-model="priceFrom" clearable label="От" type="number" hide-details outlined dense/>
              </v-col>
              <v-col>
                <v-text-field v-model="priceTo" clearable label="До" type="number" hide-details outlined dense/>
              </v-col>
            </v-row>
          </template>
        </label-view>
        <v-select
            label="Статус оплаты"
            v-model="payStatus"
            multiple
            :items="[
              {name:'Не оплачено',value:'wait'},
              {name:'Отменено',value:'cancel'},
              {name:'Оплачено',value:'success'},
            ]"
            item-text="name"
            outlined dense hide-details="auto" class="my-4"
        />
        <com-branch-autocomplete-multiple :select.sync="comBranchsSelected" globalState/>
        <insur-select @selected="insurSelected" class="mb-4"/>
        <spec-select :specsSelected.sync="specsSelected"/>
        

        <user-autocomplete-multiple name="Клиенты" :select.sync="userClientSelected"/>
        <user-autocomplete-multiple name="Направляющий" :select.sync="userLeadSelected"/>
        <user-autocomplete-multiple name="Специалисты" _type="spec" :select.sync="userSpecSelcted"/>
        <user-autocomplete-multiple name="Администраторы" _type="admin" :select.sync="userAdminSelected"/>

        <label-view title="Возраст клиента">
          <template v-slot:content>
            <v-row class="pt-2 px-1 pb-1">
              <v-col>
                <v-text-field v-model="userYearsFrom" clearable label="С" type="number" hide-details outlined dense/>
              </v-col>
              <v-col>
                <v-text-field v-model="userYearsTo" clearable label="По" type="number" hide-details outlined dense/>
              </v-col>
            </v-row>
          </template>
        </label-view>
        <v-select
            label="Пол клиента"
            v-model="userGender"
            :items="[
              {name:'Любой',value:null},
              {name:'Мужчина',value:'male'},
              {name:'Женщина',value:'female'},
            ]"
            item-text="name"
            outlined dense hide-details="auto" class="my-4"
        />
      </div>
    </template>
    <template v-slot:content="{height}">
      <graphql-table-view
          title="Список"
          query='
            query Query(
              $page: Int
              $onPage: Int
              $sortBy: String
              $sortDesc: Boolean
              $visit_begin_ge: String
              $visit_begin_le: String
              $visit_comPlace_comRoom_comFloor_comBuilding_comBranch_ids: String
              $visit_userSpec_spec_ids: String
              $visit_userSpec_user_ids: String
              $visit_user_ids: String
              $visit_user_userProfile_birth_ge: String
              $visit_user_userProfile_birth_le: String
              $visit_user_userProfile_gender: String
              $payStatuss: String
              $name: String
              $price_ge: Float
              $price_l: Float
              $insur_ids: String
              $visit_leadUser_ids: String
              $visit_userAuthor_ids: String
              $services_ids: String
            ){
              VisitService(
                page: $page
                onPage: $onPage
                sortBy: $sortBy
                sortDesc: $sortDesc
                visit_begin_ge:$visit_begin_ge
                visit_begin_le:$visit_begin_le
                visit_comPlace_comRoom_comFloor_comBuilding_comBranch_ids: $visit_comPlace_comRoom_comFloor_comBuilding_comBranch_ids
                visit_userSpec_spec_ids: $visit_userSpec_spec_ids
                visit_userSpec_user_ids: $visit_userSpec_user_ids
                visit_user_ids: $visit_user_ids
                visit_user_userProfile_birth_ge: $visit_user_userProfile_birth_ge
                visit_user_userProfile_birth_le: $visit_user_userProfile_birth_le
                visit_user_userProfile_gender: $visit_user_userProfile_gender
                payStatuss: $payStatuss
                name_like: $name
                price_ge: $price_ge
                price_l: $price_l
                insur_ids: $insur_ids
                visit_leadUser_ids: $visit_leadUser_ids
                visit_userAuthor_ids: $visit_userAuthor_ids
                visit_statuss: "complete"
                services_ids: $services_ids
              ){
                id
                code
                name
                price
                count
                discount
                priceTotal
                payStatus
                mode
                insur{
                  id
                  name
                }
                medPlan{
                  name
                }
                visit{
                  id
                  begin
                  userAuthor {
                    id
                    name
                  }
                  user{
                    id
                    name
                    fullName
                  }
                  leadUser{
                    id
                    name
                  }
                  userSpec{
                    user{
                      id
                      name
                    }
                  }
                  comPlace {
                    comDiv {
                      comBranch {
                        name
                      }
                    }
                  }
                }
                service {
                  id
                }
              }
            }
          '
          :queryVars="queryVars"
          queryMainEntity="VisitService"
          :headers="[
            { text: 'ID', value: 'id', align: 'start', class:'text-no-wrap'},
            { text: 'ID в реестре', value: 'id', align: 'start', class:'text-no-wrap'},
            { text: 'Дата', value: 'visit.begin', class:'text-no-wrap', sortable: false },
            { text: 'Время', value: 'visit.begin', class:'text-no-wrap', sortable: false },
            { text: 'Услуга', value: 'name', class:'text-no-wrap', sortable: false},
            { text: 'Визит', value: 'visit.id', class:'text-no-wrap', sortable: false},
            { text: 'План лечения', value: 'medPlan.name', class:'text-no-wrap', sortable: false},
            { text: 'Филиал', value: 'visit.comPlace.comDiv.comBranch.name', class:'text-no-wrap', sortable: false},
            { text: 'Цена', value: 'price', class:'text-no-wrap', sortable: false },
            { text: 'Количество', value: 'count', class:'text-no-wrap', sortable: false },
            { text: 'Скидка', value: 'price', class:'text-no-wrap', sortable: false },
            { text: 'Итоговая цена', value: 'priceTotal', class:'text-no-wrap', sortable: false },
            { text: 'Направляющий', value: 'visit.user.name', class:'text-no-wrap', sortable: false },
            { text: 'Клиент', value: 'visit.user.name', class:'text-no-wrap', sortable: false },
            { text: 'Специалист', value: 'visit.userSpec.user.name', class:'text-no-wrap', sortable: false },
            { text: 'Создал', value: 'visit.userAuthor.name', class:'text-no-wrap', sortable: false },
            { text: 'Организация', value: 'insur', class:'text-no-wrap', width: '100%', sortable: false },
            { text: 'Статус оплаты', value: 'payStatus', class:'text-no-wrap', width: '100%', sortable: false },
          ]"
          :height="height"
          ref="table"
          @loaded="loaded"
          class="visitService"
      >
        <template v-slot:item="{item}">
          <tr>
            <td class="text-no-wrap">{{item.id}}</td>
            <td class="text-no-wrap">{{item.service.id}}</td>
            <td class="text-no-wrap">{{$tools.date(item.visit.begin)}}</td>
            <td class="text-no-wrap">{{$tools.time(item.visit.begin)}}</td>
            <td class="text-wrap py-1">
              <!-- <div v-if="item.medPlan!==null" class="caption">План лечения: {{item.medPlan.name}}</div> -->
              <div>{{item.name}}</div>
              <service-code-view :code="item.code" class="mt-1"/>
            </td>
            <td>  
              <v-btn
                @click="visitId=item.visit.id;"
                small
              >{{ item.visit.id }}</v-btn>
            </td>
            <td class="text-wrap py-1">
              <div v-if="item.medPlan!==null">{{item.medPlan.name}}</div>
              <div v-else class="text-center">-</div>
            </td>
            <td class="text-wrap py-1">             
              <div v-if="item.visit.comPlace.comDiv!==null">{{item.visit.comPlace.comDiv.comBranch.name}}</div>
              <div v-else class="text-center">-</div>
            </td>
            <td class="text-no-wrap text-right">{{$tools.price(item.price)}}</td>
            <td class="text-no-wrap text-right">{{item.count}}</td>
            <td class="text-no-wrap text-right">{{$tools.price(item.discount)}}</td>
            <td class="text-no-wrap text-right">{{$tools.price(item.priceTotal)}}</td>
            <td class="text-no-wrap">
              <a v-if="item.visit.leadUser!==null" :href="'/panel/user/client/'+item.visit.leadUser.id+'/'" target="_blank">
                {{item.visit.leadUser.name}}
              </a>
            </td>
            <td class="text-no-wrap">
              <a :href="'/panel/user/client/'+item.visit.user.id+'/'" target="_blank">
                {{item.visit.user.fullName}}
              </a>
            </td>
            <td class="text-no-wrap">
              <a :href="'/panel/user/spec/'+item.visit.userSpec.user.id+'/'" target="_blank">
                {{item.visit.userSpec.user.name}}
              </a>
            </td>
            <td class="text-no-wrap">
              <template v-if="!!item.visit.userAuthor&&!!item.visit.userAuthor.id&&!!item.visit.userAuthor.name">
                <a :href="'/panel/user/admin/'+item.visit.userAuthor.id+'/'" target="_blank">
                  {{item.visit.userAuthor.name}}
                </a>
              </template>
            </td>
            <td class="text-no-wrap" v-if="item.insur">
              {{item.insur.name}}
            </td>
            <td class="text-no-wrap text-center" v-else>
              -
            </td>
            <td class="text-no-wrap">
              <status
                  :status="item.payStatus==='wait' ? 'error' : item.payStatus"
                  :text="item.payStatus==='wait' ? 'Не оплачено' : item.payStatus==='success' ? 'Оплачено' : 'Отменено'"
                  :width="100"
              />
            </td>
          </tr>
        </template>
      </graphql-table-view>
      <visit :visitId.sync="visitId"/>
    </template>
  </layout-view>
</template>

<script>
import State from "@/plugins/state";
import Api from "@/Api";
import LayoutView from "@/components/LayoutView";
import GraphqlTableView from "@/components/GraphqlTableView";
import ServiceCodeView from "@/views/Service/ServiceCodeView";
import LabelView from "@/components/LabelView";
import moment from "moment";
import SpecSelect from "@/views/Spec/components/SpecSelect";
//import UserSelect from "@/views/User/components/UserSelect";
import UserAutocompleteMultiple from "@/componentsV2/custom/UserAutocompleteMultiple.vue";
import CardView from "@/components/CardView";
import Status from "@/components/Status";
import InsurSelect from "@/views/Insur/components/InsurSelect";
import ServicesSelect from "@/components/ServicesSelect.vue";
import Visit from "@/views/Visit/Visit";
import ComBranchAutocompleteMultiple from "@/componentsV2/custom/ComBranchAutocompleteMultiple.vue";
import DateInterval from "@/componentsV2/base/DateInterval.vue";




export default {
  components: {
    ComBranchAutocompleteMultiple,
    InsurSelect,
    Status,
    CardView,
    SpecSelect,
    UserAutocompleteMultiple,
    LabelView, 
    ServiceCodeView, 
    GraphqlTableView, 
    LayoutView,
    ServicesSelect,
    Visit,
    DateInterval
  },
  data:()=>({
    //dateFrom:moment().subtract(1, "month").format('YYYY-MM-DD'),
    loadState: new State(),
    dateFrom:moment().format('DD.MM.YYYY'),
    dateTo:moment().format('DD.MM.YYYY'),
    name:null,
    payStatus:null,
    priceFrom:null,
    priceTo:null,
    userYearsFrom:null,
    userYearsTo:null,
    userGender:null,
    queryVars: {
      //visit_begin_ge:moment().subtract(1, "month").format('YYYY-MM-DD 00:00:00'),
      visit_begin_ge:moment().format('YYYY-MM-DD 00:00:00'),
      visit_begin_le:moment().format('YYYY-MM-DD 23:59:59'),
    },
    sumTotal:0,
    sumPayedTotal:0,
    sumUnPayedTotal:0,
    sumCanceledTotal:0,

    dateFromIsOpen: false,
    dateToIsOpen: false,
    comBranchsSelected:[],
    specsSelected:[],
    categoriesSelected:[],


    visitId:null,

    userClientSelected:[],
    userLeadSelected:[],
    userSpecSelcted:[],
    userAdminSelected:[]
  }),
  mounted(){
    // if(this.$store.state.comBranchId) {
    //   let r = '';
    //   this.$store.state.comBranchId.forEach((v)=>{
    //     r +=v+',';
    //   });
    //   this.queryVars.visit_comPlace_comRoom_comFloor_comBuilding_comBranch_ids = r;

    //   this.$refs.table.load();
    // } 
  },
  watch:{
    dateFrom(){
      this.queryVars.visit_begin_ge = moment(this.dateFrom, 'DD.MM.YYYY').format('YYYY-MM-DD 00:00:00');
      this.$refs.table.load();
    },
    dateTo(){
      this.queryVars.visit_begin_le = moment(this.dateTo, 'DD.MM.YYYY').format('YYYY-MM-DD 23:59:59');
      this.$refs.table.load();
    },
    name() {
      this.$tools.throttle(()=>{
        this.queryVars.name = this.name;
        this.$refs.table.load();
      },700);
    },
    priceFrom() {
      if(this.$tools.isEmpty(this.priceFrom)){
        delete this.queryVars.price_ge;
      } else {
        this.queryVars.price_ge = parseFloat(this.priceFrom);
      }
      this.$refs.table.load();
    },
    priceTo() {
      if(this.$tools.isEmpty(this.priceTo)){
        delete this.queryVars.price_l;
      } else {
        this.queryVars.price_l = parseFloat(this.priceTo);
      }
      this.$refs.table.load();
    },
    userYearsFrom(){
      this.queryVars.visit_user_userProfile_birth_le = this.$tools.isEmpty(this.userYearsFrom) ? '' : moment().subtract(this.userYearsFrom, "years").format('YYYY-MM-DD 00:00:00');
      this.$refs.table.load();
    },
    userYearsTo(){
      this.queryVars.visit_user_userProfile_birth_ge = this.$tools.isEmpty(this.userYearsTo) ? '' : moment().subtract(this.userYearsTo, "years").format('YYYY-MM-DD 00:00:00');
      this.$refs.table.load();
    },
    userGender(){
      this.queryVars.visit_user_userProfile_gender = this.userGender;
      this.$refs.table.load();
    },
    payStatus(){
      let a = '';
      this.payStatus.forEach((v)=>{
        a +=v+','
      });
      this.queryVars.payStatuss = a;
      this.$refs.table.load();
    },
    comBranchsSelected() {
      this.queryVars.visit_comPlace_comRoom_comFloor_comBuilding_comBranch_ids = '';
      this.comBranchsSelected.forEach(v1=>{
        this.queryVars.visit_comPlace_comRoom_comFloor_comBuilding_comBranch_ids+=v1.id+',';
      })
      this.$refs.table.load();
    },
    specsSelected(){
      this.queryVars.visit_userSpec_spec_ids = this.specsSelected.join();
      this.$refs.table.load();
    },

    userClientSelected(v){     
      this.multipleQueryString('visit_user_ids',v);
    },
    userSpecSelcted(v){     
      this.multipleQueryString('visit_userSpec_user_ids',v);
    },
    userLeadSelected(v){     
      this.multipleQueryString('visit_leadUser_ids',v);
    },
    userAdminSelected(v){     
      this.multipleQueryString('visit_userAuthor_ids',v);
    },

  },
  methods:{
    insurSelected(v){
      let r = '';
      v.forEach((v1)=>{
        r +=v1.id+',';
      });
      this.queryVars.insur_ids = r;
      this.$refs.table.load();
    },
    loaded(){
      let query = this.queryVars;
      query.onPage = 1000000;
      this.loadState.stateLoading();
      Api.graphql(`
      query Query(
              $page: Int
              $onPage: Int
              $sortBy: String
              $sortDesc: Boolean
              $visit_begin_ge: String
              $visit_begin_le: String
              $visit_comPlace_comRoom_comFloor_comBuilding_comBranch_ids: String
              $visit_userSpec_spec_ids: String
              $visit_userSpec_user_ids: String
              $visit_user_ids: String
              $visit_user_userProfile_birth_ge: String
              $visit_user_userProfile_birth_le: String
              $visit_user_userProfile_gender: String
              $payStatuss: String
              $name: String
              $price_ge: Float
              $price_l: Float
              $insur_ids: String
              $visit_leadUser_ids: String
              $visit_userAuthor_ids: String
              $services_ids: String
            ){
              VisitService(
                page: $page
                onPage: $onPage
                sortBy: $sortBy
                sortDesc: $sortDesc
                visit_begin_ge:$visit_begin_ge
                visit_begin_le:$visit_begin_le
                visit_comPlace_comRoom_comFloor_comBuilding_comBranch_ids: $visit_comPlace_comRoom_comFloor_comBuilding_comBranch_ids
                visit_userSpec_spec_ids: $visit_userSpec_spec_ids
                visit_userSpec_user_ids: $visit_userSpec_user_ids
                visit_user_ids: $visit_user_ids
                visit_user_userProfile_birth_ge: $visit_user_userProfile_birth_ge
                visit_user_userProfile_birth_le: $visit_user_userProfile_birth_le
                visit_user_userProfile_gender: $visit_user_userProfile_gender
                payStatuss: $payStatuss
                name_like: $name
                price_ge: $price_ge
                price_l: $price_l
                insur_ids: $insur_ids
                visit_leadUser_ids: $visit_leadUser_ids
                visit_userAuthor_ids: $visit_userAuthor_ids
                visit_statuss: "complete"
                services_ids: $services_ids
              ){
                id
                code
                name
                price
                count
                discount
                priceTotal
                payStatus
                mode
                insur{
                  id
                  name
                }
                medPlan{
                  name
                }
                visit{
                  id
                  begin
                  userAuthor {
                    id
                    name
                  }
                  user{
                    id
                    name
                    fullName
                  }
                  leadUser{
                    id
                    name
                  }
                  userSpec{
                    user{
                      id
                      name
                    }
                  }
                  comPlace {
                    comDiv {
                      comBranch {
                        name
                      }
                    }
                  }
                }
                service {
                  id
                }
              }
            }
        `, query, (data)=>{
            this.sumTotal = 0;
            this.sumPayedTotal = 0;
            this.sumUnPayedTotal = 0;
            this.sumCanceledTotal = 0;
            //items.forEach((v)=>{
            data.VisitService.forEach((v)=>{
              this.sumTotal+=parseFloat(v.priceTotal);
              if(v.payStatus==='success')
                this.sumPayedTotal+=parseFloat(v.priceTotal);
              if(v.payStatus==='wait')
                this.sumUnPayedTotal+=parseFloat(v.priceTotal);
              if(v.payStatus==='cancel')
                this.sumCanceledTotal+=parseFloat(v.priceTotal);
            })
            this.loadState.stateSuccess()
        }, (e)=>{
          console.log(e);
          this.loadState.stateError(e)
        });


    },
    multipleQueryString(type,v){
      let r = '';
      v.forEach((v1)=>{
        r +=v1.id+',';
      });
      this.queryVars[type] = r;
      this.$refs.table.load();
    },
  }
}
</script>

<style lang="scss">
.visitService table tr{
  th, td {
    width: 100px !important;
    white-space: nowrap !important;
  }
  th:nth-child(3), td:nth-child(3) {
    width: 100% !important;
  }
}
</style>

