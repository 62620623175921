<template>
  <v-autocomplete
      v-model="selected"
      :items="items"
      :search-input.sync="text"
      :label="typeof single==='undefined' ? 'Специальности' : 'Специальность'"
      outlined
      dense
      hide-details="auto"
      :multiple="typeof single==='undefined'"
      class="mb-4"
      item-text="name"
      item-value="id"
      @click="load()"
  >
    <template v-slot:append>
      <v-sheet v-if="state.isLoading" width="24" height="24" class="d-flex align-center justify-center">
        <v-progress-circular color="primary" size="16" width="2" indeterminate/>
      </v-sheet>
    </template>
    <template v-slot:no-data>
      <div v-if="state.isLoading"></div>
      <v-list-item v-else>
        <v-list-item-content>
          <v-list-item-title>{{state.isLoading ? '' : 'Не найдено'}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:item="{item}">
      <v-list-item-title>
        <div class="py-2">
          <v-avatar :color="item.color" size="12" class="mr-2"/>{{item.name}}
        </div>
      </v-list-item-title>
    </template>
    <template v-slot:selection="{item}">
      <v-chip class="full-width mt-2" small style="width:95%">
        <v-row no-gutters>
          <v-col cols="auto"><v-avatar :color="item.color" class="circleIcon mr-2"/></v-col>
          <v-col class="text-overflow">{{item.name}}</v-col>
          <v-col cols="auto"><v-icon small @click.stop="selected=Array.isArray(selected) ? selected.filter((v)=>v!==item.id) : null">mdi-close-circle</v-icon></v-col>
        </v-row>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>

import State from "@/plugins/state";
import Api from "@/Api";

export default {

  props:[
  'single','specsSelected','specs','value'
  ],
  data:()=>({
    items:[],
    text:null,
    selected: null,
    firstOpen: false,
    cookieValue: null,
    state: new State(),
  }),
  watch:{
    selected(){
      let arr =  [];
      if(Array.isArray(this.selected)) {
        this.selected.forEach((v)=>{
          this.items.forEach((v1)=>{
            if(v1.id===v)
              arr.push(v1);
          });
        });
   

        this.$emit('selected', arr);

      } else {
        this.items.forEach((v1)=>{
          if(v1.id===this.selected)
            arr.push(v1);
        });
    
        this.$emit('selected', arr.length===0 ? null : arr[0]);
      }

      if (typeof this.specsSelected !== "undefined") {
        this.specSelect(arr);
        if(this.selected.length === 0){  
          this.$cookie.delete('specsSelected')
        }
        this.saveSpecId();
      }

      
    }
  },
  mounted(){
    if(typeof this.value!=='undefined'){
      this.selected = this.value;
      this.load(false);
    } else {
      this.cookieValue = JSON.parse(this.$cookie.get('specsSelected'));
      if(this.cookieValue!==null&&typeof single==='undefined') {
        // this.$emit('selected', this.cookieValue);
        this.$emit('update:specsSelected', this.cookieValue);
        this.load();
      }
    }
  },
  methods:{
    load(cookies=true){
      if(this.items.length===0){
        let query = `
          query Query{
            Spec(sortBy:"name" sortDesc:false){
              id
              name
              color
            }
          }
        `;

        this.state.stateLoading();
        Api.graphql(query, {}, (data)=>{
          this.state.stateSuccess()
          this.items = this.items = data.Spec;
          if (!this.firstOpen&&cookies) {
            if (this.cookieValue !== null) {
              let arr =  [];           
              this.cookieValue.forEach((v)=>{
                  arr.push(v.id);
              });
              this.selected = arr 
              this.firstOpen = true
            }
          }
          
        }, (e)=>{
          this.state.stateError(e)
        });
      }
    },
    saveSpecId() {
      if(this.selected.length>0&&this.selected!==null) {    
        let arr =  [];
        this.selected.forEach((v)=>{
          this.items.forEach((v1)=>{
            if(v1.id===v)
              arr.push(v1);
          });
        });
        this.$cookie.set('specsSelected', JSON.stringify(arr), 30);
      }
    },
    specSelect(s){
      let r = [];
        s.forEach((s1)=>{
          r.push(Number(s1.id));
        });
        this.$emit('update:specsSelected', r);
    },
  }
}
</script>

